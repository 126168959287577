<template>
  <Tabs :items="tabs.items" :activeTab="tabs.activeTab">
    <template v-slot:creditCard>
      <CreditCardList />
      <Form />
    </template>

    <template v-slot:billet>
      <BilletWrapper />
    </template>
  </Tabs>
</template>

<script>
import Tabs from 'components/paymentMethod/Tabs'
import CreditCardList from 'components/paymentMethod/CreditCardList'
import Form from 'components/paymentMethod/Form'
import BilletWrapper from 'components/paymentMethod/BilletWrapper'

export default {
  name: 'TabsWrapper',
  components: {
    Tabs,
    CreditCardList,
    Form,
    BilletWrapper
  },
  data () {
    return {
      tabs: {
        items: ['Cartão de crédito', 'Boleto bancário'],
        activeTab: 0
      }
    }
  }
}
</script>
