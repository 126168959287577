<template>
  <img
    class="platform"
    :srcset="`
      ${require(`./img/${imageName}@3x.png`)} 3x,
      ${require(`./img/${imageName}@2x.png`)} 2x,
      ${require(`./img/${imageName}.png`)} 1x
    `"
    :src="require(`./img/${imageName}.png`)"
    :alt="title"
  >
</template>

<script>
export default {
  name: 'PlatformImage',
  props: {
    image: {
      type: String,
      require: true
    },
    title: {
      type: String,
      require: true
    }
  },
  computed: {
    imageName () {
      const images = {
        home: 'home',
        reports: 'reports'
      }

      return images[this.image] || images.home
    }
  }
}
</script>

<style lang="scss" scoped>
.platform {
  display: block;
  margin: 0 auto;
  max-width: 100%;

  @media (min-width: 1200px) {
    height: 266px;
  }
}
</style>
