<template>
  <ul class="credit-card-list">
    <li v-for="creditCard in creditCardList" :key="creditCard.img">
      <img
        :src="require(`@/assets/svg/credit-card-flags/${creditCard.img}`)"
        :alt="creditCard.title"
      >
    </li>
  </ul>
</template>

<script>
export default {
  name: 'CreditCardList',
  data () {
    return {
      creditCardList: [
        { title: 'Visa', img: 'visa.svg' },
        { title: 'Mastercard', img: 'mastercard.svg' },
        { title: 'Elo', img: 'elo.svg' },
        { title: 'Hipercard', img: 'hipercard.svg' },
        { title: 'Diners', img: 'diners.svg' },
        { title: 'Amex', img: 'amex.svg' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.credit-card-list {
  align-items: center;
  background-color: $gray-1;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  list-style-type: none;
  margin-bottom: 30px;
  padding: 10px 0;

  li {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: center;

    img {
      max-width: 28px;

      @media (min-width: 1200px) {
        height: 28px;
      }
    }
  }
}
</style>
