'use strict'

import AdyenEncrypt from 'adyen-encrypt'

export default {
  encrypt ({ number, holderName, expiryMonth, expiryYear, cvv }) {
    const adyen = new AdyenEncrypt(process.env.VUE_APP_ADYEN_KEY, {
      enableValidations: true
    })

    return adyen.encrypt({
      number: String(number).replace(/\D/g, ''),
      cvc: String(cvv),
      holderName: String(holderName).toUpperCase(),
      generationtime: new Date().toISOString(),
      expiryMonth: String(expiryMonth),
      expiryYear: String(expiryYear)
    })
  }
}
