<template>
  <div class="banner">
    <img src="./img/foto.png" alt="">
    <h2 class="page__title">Contar com a 99Empresas é ter mais praticidade para você e para o seu negócio.</h2>
  </div>
</template>

<script>
export default {
  name: 'Banner'
}
</script>

<style lang="scss" scoped>
.banner {
  background: url(./img/ellipse.png) no-repeat 40px 0;
  background-size: 90%;
  display: flex;
  height: 245px;
  margin: 80px 0 0;
  position: relative;
  width: 100%;

  > img {
    height: 150px;
    margin-top: 60px;
    width: 150px;
  }

  .page__title {
    padding: 80px 0 0 16px;
  }
}
</style>
