'use strict'

import { cardType } from '@polvo-labs/card-type'

const creditCard = {
  getFlag (str = '') {
    const value = str.replace(/\D/g, '')
    return cardType(value)
  },

  getMask (flag, chars = '') {
    const flags = {
      visa: chars.trim().length === 13 ? '#### #### #####' : '#### #### #### ####',
      mastercard: '#### #### #### ####',
      elo: '#### #### #### ####',
      hipercard: '#### #### #### ####',
      diners: '#### ###### ####',
      amex: '#### ###### #####'
    }

    return flags[flag] || flags.mastercard
  },

  getCountChars (flag) {
    const twoSpaces = 2
    const treeSpaces = 3
    const flags = {
      visa: {
        minlength: 13 + twoSpaces,
        maxlength: 16 + treeSpaces
      },
      mastercard: {
        minlength: 16 + treeSpaces,
        maxlength: 16 + treeSpaces
      },
      elo: {
        minlength: 16 + treeSpaces,
        maxlength: 16 + treeSpaces
      },
      hipercard: {
        minlength: 13 + treeSpaces,
        maxlength: 19 + treeSpaces
      },
      diners: {
        minlength: 14 + twoSpaces,
        maxlength: 16 + twoSpaces
      },
      amex: {
        minlength: 15 + twoSpaces,
        maxlength: 15 + twoSpaces
      }
    }

    return flags[flag] || flags.mastercard
  },

  getConfig (str) {
    const flag = this.getFlag(str)
    const mask = this.getMask(flag, str)
    const { minlength, maxlength } = this.getCountChars(flag)

    return {
      flag,
      mask,
      minlength,
      maxlength
    }
  }
}

export default creditCard
