<template>
  <div>
    <Disclaimer class="my-1 pa-0 text--left" no-background>
      O boleto de cobrança será enviado por e-mail todo dia 1 de cada mês unificando as cobranças das corridas e as taxas de serviço. O prazo de pagamento é de 8 dias após o envio do boleto.
    </Disclaimer>

    <Disclaimer class="text--left">
      Clicando em "concluir o cadastro" você declara ter autorizado a cobrança mensal por boleto bancário.
    </Disclaimer>

    <FormFooter right>
      <v-btn
        type="button"
        color="warning--gradient"
        :loading="isLoading"
        :disabled="isLoading"
        depressed
        rounded
        large
        @click="handleClick"
      >
        Concluir cadastro
      </v-btn>
    </FormFooter>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { STATUS_IS_LOADING, STATUS_SUCCESS, STATUS_FAILED } from 'utils/constants'
import { isObject } from 'utils/functions'
import persistProspect from 'mixins/persistProspect'
import Disclaimer from 'components/Disclaimer'
import FormFooter from 'components/FormFooter'

export default {
  name: 'BilletWrapper',
  components: {
    Disclaimer,
    FormFooter
  },
  mixins: [persistProspect],
  computed: {
    ...mapState({
      prospect: ({ prospect }) => prospect.data
    }),

    isLoading () {
      return this.currentStatus === STATUS_IS_LOADING
    },

    isSuccess () {
      return this.currentStatus === STATUS_SUCCESS
    },

    isFailed () {
      return this.currentStatus === STATUS_FAILED
    }
  },
  data () {
    return {
      currentStatus: null
    }
  },
  methods: {
    ...mapActions({
      showAlert: 'snackbar/show'
    }),

    isValidObject (val) {
      return isObject(val)
    },

    isInvalidRequestResponse (res) {
      return !this.isValidObject(res) || (this.isValidObject(res) && !res.valid)
    },

    redirectToNextStep () {
      this.$router.push({ name: 'RegistrationSubmitted' })
    },

    async handleClick () {
      try {
        this.currentStatus = STATUS_IS_LOADING

        const { id } = this.prospect
        const { data: res } = await this.$apiService.step6.billPayment({ id })

        if (this.isInvalidRequestResponse(res)) {
          throw new Error()
        }

        this.saveProspect({
          currentStep: 4,
          data: {
            paymentMethod: 'billet'
          }
        })

        this.currentStatus = STATUS_SUCCESS
        this.redirectToNextStep()
      } catch (error) {
        this.currentStatus = STATUS_FAILED
        this.showAlert({
          type: 'error',
          message: 'Ocorreu um erro ao concluir o cadastro. Escolha outra forma de pagamento.',
          timeout: 8000
        })

        return error
      }
    }
  }
}
</script>
