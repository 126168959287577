<template>
  <section class="page page--company-registration" data-js="page">
    <div v-if="hasDesktopResolution" class="page__left">
      <div class="page__content page__content--left">
        <PageHeader :title="hasDesktopResolution ? pageTitle : ''" />

        <div class="page__body">
          <Tips />
        </div>
      </div>
    </div>

    <div class="page__right">
      <div class="page__content page__content--right">
        <PageHeader
          v-if="!hasDesktopResolution"
          :title="hasDesktopResolution ? pageTitle : ''"
        />

        <div class="page__body">
          <PageTitles v-if="!hasDesktopResolution" class="mb-8">
            <template v-slot:title v-html="pageTitle">
              <div v-html="pageTitle" />
            </template>
          </PageTitles>

          <Breadcrumb :step="step" />
          <div class="boleto__tip">
            Para permitir pagamentos com boleto, entre em contato com o representante da sua conta
          </div>
          <TabsWrapper />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import mediaQueries from 'mixins/mediaQueries'
import PageHeader from 'components/pageHeader'
import PageTitles from 'components/PageTitles'
import Breadcrumb from 'components/Breadcrumb'
import Tips from 'components/paymentMethod/tips'
import TabsWrapper from 'components/paymentMethod/TabsWrapper'

export default {
  name: 'PaymentMethod',
  mixins: [mediaQueries],
  metaInfo () {
    return {
      titleTemplate: this.routeTitle + ' | %s'
    }
  },
  components: {
    PageHeader,
    PageTitles,
    Breadcrumb,
    Tips,
    TabsWrapper
  },
  computed: {
    ...mapState({
      creditCardAttempts: ({ prospect }) => prospect.creditCardAttempts,
      userName: ({ prospect }) => prospect.data.name
    }),

    routeTitle () {
      return this.$route.meta.title
    },

    pageTitle () {
      return `<span>Olá, ${this.userName}!</span> Vamos terminar de cadastrar sua empresa?`
    },

    hasDesktopResolution () {
      return this.isDesktop || this.isLargeDesktop || this.isExtraLargeDesktop
    }
  },
  data () {
    return {
      step: 3
    }
  },
  methods: {
    sendGTMDataLayer () {
      if (!this.creditCardAttempts) {
        this.$GTMDataLayer.send({
          event: 'cadastro-step3',
          'step-fom': '3-forma-de-pagamento'
        })
      }
    }
  },
  mounted () {
    this.sendGTMDataLayer()
  }
}
</script>

<style lang="scss" scoped>
.boleto__tip {
  font-size: 14px;
  margin: 16px 0px 8px 0px;
  color: #ff8200;
}
.page__content--right {

  @media (min-width: 1200px) {
    padding-left: 98px;
  }
}

.page__left {
  background-color: $gray-1;
  border-right: 1px solid $gray-3;
  position: relative;

  &:before {
    background-color: $warning-2;
    content: '';
    height: calc(100% - 545px);
    left: 0;
    position: absolute;
    right: 0;
    top: 545px;
    z-index: 0;
  }
}

.page__right {
  background-color: $white;
}

.page__content--left {
  padding-bottom: 0;
}
</style>
