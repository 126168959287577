<template>
  <div class="tips">
    <PlatformImage image="reports" title="Plataforma para gerenciamento de corridas" />
    <CarCategories />
    <Banner />
  </div>
</template>

<script>
import PlatformImage from 'components/platformImage'
import CarCategories from './CarCategories'
import Banner from './Banner'

export default {
  name: 'Tips',
  components: {
    PlatformImage,
    CarCategories,
    Banner
  }
}
</script>

<style lang="scss" scoped>
.tips {
  max-width: 470px;
  padding: 30px 0;
}

/* .tips {
  padding: 30px 0;
  transform: translateY(-50%);
  position: relative;
  top: 45%;
} */
</style>
