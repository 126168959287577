<template>
  <div class="tabs">
    <div v-if="items.length && hasCredit" class="tabs__buttons">
      <v-btn
        v-for="(item, index) in items"
        :key="item"
        :color="`${tab === index ? 'primary' : 'default'}`"
        depressed
        rounded
        @click="tab = index"
      >
        {{ item }}
      </v-btn>
    </div>

    <div class="tabs__content">
      <div v-if="tab === 0">
        <slot name="creditCard"></slot>
      </div>

      <div v-if="tab === 1">
        <slot name="billet"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Tabs',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    activeTab: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapState({
      hasCredit: ({ prospect }) => prospect.data.hasCredit
    })
  },
  data () {
    return {
      tab: 0
    }
  },
  methods: {
    handleActiveTab () {
      this.tab = this.activeTab
    }
  },
  watch: {
    activeTab () {
      this.handleActiveTab()
    }
  },
  mounted () {
    this.handleActiveTab()
  }
}
</script>

<style lang="scss" scoped>
.tabs {
  padding-top: 10px;
}

.tabs__buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 32px;

  .v-btn {
    margin: 0 8px;
  }
}
</style>
