<template>
  <ul class="car-categories">
    <li>
      <img src="./img/pop.svg" alt="Pop">
    </li>
    <li>
      <img src="./img/99taxi.svg" alt="99Táxi">
    </li>
    <li>
      <img src="./img/top.svg" alt="Táxi top">
    </li>
    <li>
      <img src="./img/99taxi.svg" alt="Táxi comum">
    </li>
  </ul>
</template>

<script>
export default {
  name: 'CarCategories'
}
</script>

<style lang="scss" scoped>
.car-categories {
  align-items: center;
  display: inline-flex;
  list-style-type: none;
  margin-bottom: 32px;
  justify-content: center;
  padding: 0;
  width: 100%;

  li {
    align-items: center;
    display: flex;
    justify-content: center;

    & + li {
      margin-left: 5px;
    }

    img {
      max-width: 100%;

      @media (min-width: 1200px) {
        height: 41px;
        width: 90px;
      }
    }
  }
}
</style>
