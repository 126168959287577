<template>
  <ol
    class="breadcrumb"
    :class="[
      { 'breadcrumb--step1': step === 1 },
      { 'breadcrumb--step2': step === 2 },
      { 'breadcrumb--step3': step === 3 }
    ]"
  >
    <li class="breadcrumb__item breadcrumb__item--1">
      <div class="breadcrumb__item__info">
        <span class="breadcrumb__item__number">1</span>
        <span class="breadcrumb__item__text">Cadastro<br> do usuário</span>
      </div>
    </li>

    <li class="breadcrumb__item breadcrumb__item--2">
      <div class="breadcrumb__item__info">
        <span class="breadcrumb__item__number">2</span>
        <span class="breadcrumb__item__text">Cadastro<br> da empresa</span>
      </div>
    </li>

    <li class="breadcrumb__item breadcrumb__item--3">
      <div class="breadcrumb__item__info">
        <span class="breadcrumb__item__number">3</span>
        <span class="breadcrumb__item__text">Forma de<br> pagamento</span>
      </div>
    </li>
  </ol>
</template>

<script>
export default {
  name: 'Breadcrumb',
  props: {
    step: {
      type: Number,
      default: 1
    }
  }
}
</script>

<style lang="scss" scoped>
.breadcrumb {
  display: flex;
  height: 48px;
  list-style-type: none;
  margin-bottom: 36px;
  justify-content: center;
  padding: 0;
  position: relative;
  width: 100%;

  &:before,
  &:after {
    background-color: $gray-3;
    border-radius: 4px;
    bottom: 0;
    content: '';
    display: block;
    height: 4px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 1;
  }

  &:before {
    background-color: $gray-3;
  }

  &:after {
    transition: width .5s;
    width: 0;
  }

  .breadcrumb__item {
    height: 48px;
    position: absolute;
    width: 100%;
    z-index: 2;

    @media (min-width: 992px) {
      width: 32px;
    }

    &:before {
      background-color: $gray-3;
      border: 4px solid $white;
      border-radius: 50%;
      bottom: 0;
      content: '';
      display: block;
      height: 16px;
      margin-bottom: -6px;
      position: absolute;
      transition: background-color .2s .05s ease-out;
      width: 16px;
      z-index: 2;
    }

    .breadcrumb__item__info {
      display: none;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);

      @media (min-width: 992px) {
        display: inline-block;
        left: auto;
        transform: initial;
      }
    }

    .breadcrumb__item__number {
      align-items: center;
      background-color: $gray-3;
      border-radius: 50%;
      color: #fff;
      display: flex;
      font-family: $title-font;
      font-size: 16px;
      font-weight: 800;
      height: 32px;
      justify-content: center;
      margin-bottom: 16px;
      position: relative;
      transition: background-color .2s .05s ease-out;
      width: 32px;
    }

    .breadcrumb__item__text  {
      align-items: center;
      color: $gray-5;
      display: flex;
      font-family: $title-font;
      left: 40px;
      line-height: normal;
      position: absolute;
      top: 50%;
      transform: translateY(-75%);
      width: 90px;
    }

    &.breadcrumb__item--1 {
      left: 0;

      &:before {
        left: 0;
      }

      .breadcrumb__item__info {
        display: inline-block;

        @media (min-width: 992px) {
          left: -8px;
        }
      }
    }

    &.breadcrumb__item--2 {

      &:before {
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &.breadcrumb__item--3 {
      right: 0;

      &:before {
        right: 0;
      }

      .breadcrumb__item__info {

        @media (min-width: 992px) {
          right: -8px;
        }
      }
    }
  }

  &.breadcrumb--step1 {
    left: 0;

    .breadcrumb__item {

      .breadcrumb__item__text  {
        color: $gray-7;
      }

      &.breadcrumb__item--1 {
        font-weight: 700;

        &:before {
          background-color: #2dcc70;
        }

        .breadcrumb__item__number {
          background-color: #2dcc70;
        }
      }

      &.breadcrumb__item--2,
      &.breadcrumb__item--3 {

        .breadcrumb__item__info  {
          display: none;

          @media (min-width: 992px) {
            display: inline-flex;
          }
        }
      }
    }
  }

  &.breadcrumb--step2 {

    &:after {
      background-color: #2dcc70;
      width: 50%;
    }

    .breadcrumb__item {

      .breadcrumb__item__info {
        display: inline-block;
      }

      &.breadcrumb__item--1,
      &.breadcrumb__item--2 {

        .breadcrumb__item__number {
          background-color: #2dcc70;
        }

        .breadcrumb__item__text  {
          color: $gray-7;
        }
      }

      &.breadcrumb__item--2 {
        font-weight: 700;
      }

      &.breadcrumb__item--1,
      &.breadcrumb__item--3 {

        .breadcrumb__item__info {
          display: none;

          @media (min-width: 992px) {
            display: inline-flex;
          }
        }
      }

      &.breadcrumb__item--1,
      &.breadcrumb__item--2 {

        &:before {
          background-color: #2dcc70;
        }
      }
    }
  }

  &.breadcrumb--step3 {

    &:after {
      background-color: #2dcc70;
      width: 100%;
    }

    .breadcrumb__item {

      .breadcrumb__item__info {
        display: inline-block;
      }

      &.breadcrumb__item--3 {
        font-weight: 700;
      }

      &.breadcrumb__item--1,
      &.breadcrumb__item--2,
      &.breadcrumb__item--3 {

        .breadcrumb__item__number {
          background-color: #2dcc70;
        }

        .breadcrumb__item__text  {
          color: $gray-7;
        }
      }

      &.breadcrumb__item--1,
      &.breadcrumb__item--2 {

        .breadcrumb__item__info  {
          display: none;

          @media (min-width: 992px) {
            display: inline-flex;
          }
        }
      }

      &.breadcrumb__item--1,
      &.breadcrumb__item--2,
      &.breadcrumb__item--3 {

        &:before {
          background-color: #2dcc70;
        }
      }
    }
  }
}
</style>
